/* Links */

a {
    text-decoration: none;
  }
  
  .link-arrow {
    color: var(--color-accent);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .link-arrow::after {
    content: "-->";
    margin-left: 5px;
    transition: margin 0.15s;
  }
  
  .link-arrow:hover::after {
    margin-left: 10px;
  }
  
  @media screen and (min-width: 1024px) {
    .link-arrow {
      font-size: 1.5rem;
    }
  }