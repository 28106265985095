
/* Collapsibles */

.collapsible__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-transform: uppercase;
}

.collapsible__heading {
  margin-top: 0;
  font-size: 3rem;
}

.collapsible__chevron {
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

/* .collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}

.expanded .collapsible__content {
  max-height: 100%;
  opacity: 1;
} */

@media screen and (max-width: 768px) {
  .collapsible__content {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s;
  }

  .expanded .collapsible__chevron {
      transform: rotate(0);
  }
  .expanded .collapsible__content {
      display: block;
      opacity: 1;
      max-height: 100vh;
  }
}