@import "./normalize.css";
@import "./style/typography.css";
@import "./style/Media.css";
@import "./style/block.css";
@import "./style/collapsible.css";
@import "./style/grid.css";
@import "./style/icon.css";
@import "./style/Feature.css";
@import "./style/Link.css";
@import "./style/Badge.css";
@import "./style/List.css";
@import "./style/Button.css";
@import "./style/Input.css";
@import "./style/Card.css";
@import "./style/quote.css";
@import "./style/Showcase.css";

:root {
  --color-primary: #2584ff;
  --color-secondary: #00d9ff;
  --color-accent: #ff3400;
  --color-headings: #1b0760;
  --color-body: #918ca4;
  --color-body-darker: #5c5577;
  --color-border: #ccc;
  --border-radius: 30px;
  --color-white: #fff;
}

*, *::after, *::before {
  box-sizing: border-box;
}



/* Sign-up Callout */

.callout-signup {
  transform: translateY(5rem);
}