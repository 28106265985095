/* Footer */

.footer {
    background: #232323;
    padding-top: 10rem;
  }
  
  .footer a {
    color: #777;
    transition: color 0.3s;
  }
  
  .footer a:hover {
    color: var(--color-white);
  }
  
  .footer__section {
    padding: 2rem;
    border-bottom: 1px solid #393939;
  }
  
  .footer__section .list {
    margin: 0;
  }
  
  .footer__heading {
    color: #d2d0db;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .footer__brand {
    margin-top: 5rem;
    text-align: center;
  }
  
  .footer__brand img {
    max-width: 230px;
  }
  
  .footer__copyright {
    font-size: 2.1rem;
    color: var(--color-white);
    opacity: 0.3;
  }
  
  @media screen and (min-width: 768px) {
    .footer {
      font-size: 1.5rem;
    }
    .footer__sections {
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
    .footer .collapsible__chevron {
      display: none;
    }
    .footer .collapsible__content {
      opacity: 1;
      max-height: 100%;
    }
    .footer__brand {
      order: -1;
      margin-top: 1rem;
    }
    .footer__copyright {
      font-size: 1.5rem;
    }
    .footer__section {
      border: 0;
    }
    .footer__heading {
      font-size: 1.6rem;
    }
  }