/* Lists */

.list {
    list-style: none;
    padding-left: 0;
  }
  
  .list--inline .list__item {
    display: inline-block;
    margin-right: 2rem;
  }
  
  .list--tick {
    list-style-image: url(../../../public/images/tick.svg);
    padding-left: 3rem;
    color: var(--color-headings);
  }
  
  .list--tick .list__item {
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  
  @media screen and (min-width: 1024px) {
    .list--tick .list__item {
      padding-left: 0;
    }
  }