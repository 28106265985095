/* Icons */

.icon {
    width: 40px;
    height: 40px;
  }
  
  .icon--small {
    width: 30px;
    height: 30px;
  }
  
  .icon--primary {
    fill: var(--color-primary);
  }
  
  .icon--white {
    fill: #fff;
  }
  
  .icon-container {
    background: #f3f9fa;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .icon-container--accent {
    background: var(--color-accent);
  }

  .dropdown__icon {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }