/* Inputs */

.input {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    color: var(--color-headings);
    font-size: 2rem;
    outline: 0;
    padding: 1.5rem 3.5rem;
  }
  
  ::placeholder {
    color: #cdcbd7;
  }
  
  .input-group {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    display: flex;
  }
  
  .input-group .input {
    border: 0;
    flex-grow: 1;
    padding: 1.5rem 2rem;
    width: 0;
  }
  
  .input-group .btn {
    margin: 4px;
  }
  
  @media screen and (min-width: 1024px) {
    .input {
      font-size: 1.5rem;
    }
  }