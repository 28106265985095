/* Typography */

:selection {
    background: var(--color-primary);
    color: #fff;
  }
  
  html {
    font-size: 62.5%;
  }
  
  img {
    width: 100%;
  }
  
  body {
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 1.5;
    color: var(--color-body);
  }
  
  h1, h2, h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  
  h1 {
    font-size: 6rem;
  }
  
  h2 {
    font-size: 4rem;
  }
  
  h3 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.3;
  }
  
  p {
    margin-top: 0;
  }
  
  @media screen and (min-width: 1024px) {
    body {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 8rem;
    }
    h2 {
      font-size: 4rem;
    }
    h3 {
      font-size: 2.4rem;
    }
  }