/* Navigation Bar */

.nav {
    background: #000;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
    align-items: center;
  }
  
  .nav__list {
    width: 100%;
    margin: 0;
  }
  
  .nav__item {
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #222;
    cursor: pointer;
  }
  
  .nav__item:last-of-type {
    border-bottom: none;
  }
  
  .nav__item>a {
    color: #d2d0db;
    transition: color 0.3s;
  }
  
  .nav__item>a:hover {
    color: #fff;
  }
  
  .nav__toggler {
    opacity: 0.5;
    transition: box-shadow 0.15s;
    cursor: pointer;
  }
  
  .nav.collapsible--expanded .nav__toggler {
    opacity: 1;
    box-shadow: 0 0 0 3px #666;
    border-radius: 5px;
  }
  
  .nav__brand {
    transform: translateY(5px);
    width: 150px;
  }
  
  @media screen and (min-width: 768px) {
    .nav__toggler {
      display: none;
    }
    .nav__list {
      width: auto;
      display: flex;
      font-size: 1.6rem;
      max-height: 100%;
      opacity: 1;
    }
    .nav__item {
      border: 0;
    }
  }