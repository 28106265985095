/* Domain Block */

.block-domain .input-group {
    box-shadow: 0 0 30px 20px #e6ebee;
    border: 0;
    margin: 4rem auto;
    max-width: 670px;
  }
  
  .block-domain__prices {
    color: var(--color-headings);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 6rem);
    font-size: 2rem;
    font-weight: 600;
    justify-items: center;
    max-width: 700px;
    margin: 0 auto;
  }
  
  @media screen and (min-width: 768px) {
    .block-domain__prices {
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
  }