/* Callouts */

.callout {
    padding: 4rem;
    border-radius: 5px;
  }
  
  .callout--primary {
    background: var(--color-primary);
    color: #fff;
  }
  
  .callout__heading {
    color: #fff;
    margin-top: 0;
    font-size: 3rem;
  }
  
  .callout .btn {
    justify-self: center;
    align-self: center;
  }
  
  .callout__content {
    text-align: center;
  }
  
  @media screen and (min-width: 768px) {
    .callout .grid--1x2 {
      grid-template-columns: 1fr auto;
    }
    .callout__content {
      text-align: left;
    }
    .callout .btn {
      justify-self: start;
      margin: 0 2rem;
    }
  }