/* Buttons */

.btn {
    border-radius: 40px;
    border: 0;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0;
    outline: 0;
    padding: 2rem 4vw;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
  
  .btn .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
  }
  
  .btn--primary {
    background: var(--color-primary);
    color: #fff;
  }
  
  .btn--primary:hover {
    background: #3a8ffd;
  }
  
  .btn--secondary {
    background: var(--color-secondary);
    color: #fff;
  }
  
  .btn--secondary:hover {
    background: #05cdf0;
  }
  
  .btn--accent {
    background: var(--color-accent);
    color: #fff;
  }
  
  .btn--accent:hover {
    background: #ec3000;
  }
  
  .btn--outline {
    background: #fff;
    color: var(--color-headings);
    border: 2px solid var(--color-headings);
  }
  
  .btn--outline:hover {
    background: var(--color-headings);
    color: #fff;
  }
  
  .btn--stretched {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
  .btn--block {
    width: 100%;
    display: inline-block;
  }
  
  @media screen and (min-width: 1024px) {
    .btn {
      font-size: 1.5rem;
    }
  }