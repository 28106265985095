/* Quotes */

.quote {
    font-size: 3rem;
    font-style: italic;
    color: var(--color-body-darker);
    line-height: 1.3;
  }
  
  .quote__text::before {
    content: open-quote;
  }
  
  .quote__text::after {
    content: close-quote;
  }
  
  .quote__author {
    font-size: 2rem;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0;
  }
  
  .quote__organization {
    color: var(--color-headings);
    opacity: 0.4;
    font-size: 1.5rem;
    font-style: normal;
  }
  
  .quote__line {
    position: relative;
    bottom: 10px;
  }
  
  @media screen and (min-width: 1024px) {
    .quote {
      font-size: 2rem;
    }
    .quote__author {
      font-size: 2.4rem;
    }
    .quote__organization {
      font-size: 1.6rem;
    }
  }