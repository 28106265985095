/* Cards */

.card {
    border-radius: 7px;
    box-shadow: 0 0 20px 10px #f3f3f3;
    overflow: hidden;
  }
  
  .card__header, .card__body {
    padding: 2rem 7%;
  }
  
  .card--primary .card__header {
    background: var(--color-primary);
    color: #fff;
  }
  
  .card--secondary .card__header {
    background: var(--color-secondary);
    color: #fff;
  }
  
  .card--primary .badge--primary {
    background: #126de4;
  }
  
  .card--secondary .badge--secondary {
    background: #02cdf1;
  }
  