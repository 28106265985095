/* Features */

.feature {
    gap: 4rem 2rem;
    margin: 12rem 0;
  }
  
  .feature:first-of-type {
    margin-top: 6rem;
  }
  
  .feature__heading {
    margin: 1rem 0;
  }
  
  @media screen and (min-width: 768px) {
    .feature:nth-of-type(even) .feature__content {
      order: 2;
    }
  }