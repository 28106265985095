/* Blocks */
.block {
    --pading-vertical: 6rem;
    padding: var(--pading-vertical) 2rem;
}
.block__header {
    text-align: center;
    margin-bottom: 4rem;
}
.block__heading {
    margin-top: 0;
    font-size: 4rem;
}

.block--dark {
    background: #000;
    color: #7b858b;
}
.block--dark .block__heading {
    color: var(--color-white);
}

.block--skewed-right {
    padding-bottom: calc(var(--pading-vertical) + 4rem);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 85%);
}
.block--skewed-left {
    padding-bottom: calc(var(--pading-vertical) + 4rem);
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0 100%);
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .hero {
        padding-top: 0;
    }
    .hero__content {
        text-align: left;
        align-self: center;
    }
    .block__header {
        margin-bottom: 6rem;
    }
}