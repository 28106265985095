/* Showcase Block */

@media screen and (min-width: 768px) {
    .block-showcase .grid {
      grid-template-columns: 50% 50%;
    }
    .block-showcase__image {
      justify-self: end;
    }
    .block-showcase__image>img {
      width: auto;
      max-width: 700px;
    }
  }